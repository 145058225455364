/**
 * Do not edit directly
 * Generated on Fri, 04 Apr 2025 20:41:59 GMT
 */

:root, ::before, ::after {
  --color-indigo-100: #F4F6FF;
  --color-indigo-200: #E7EBFF;
  --color-indigo-300: #ADBBFF;
  --color-indigo-400: #7E93FB;
  --color-indigo-500: #4561EC;
  --color-indigo-600: #22389C;
  --color-indigo-700: #061558;
  --color-gray-100: #F5F5F6;
  --color-gray-200: #E5E5E8;
  --color-gray-300: #BEBEC4;
  --color-gray-400: #8F8F95;
  --color-gray-500: #58585A;
  --color-gray-600: #373839;
  --color-gray-700: #17181C;
  --color-violet-100: #F9F5FF;
  --color-violet-200: #EFE3FF;
  --color-violet-300: #DBBFFF;
  --color-violet-400: #B67EFF;
  --color-violet-500: #8D4BDF;
  --color-violet-600: #582797;
  --color-blue-100: #F2F8FE;
  --color-blue-200: #C7E6FF;
  --color-blue-300: #AEDAFD;
  --color-blue-400: #77BFF7;
  --color-blue-500: #298EDE;
  --color-blue-600: #1569AA;
  --color-teal-100: #EFFEFF;
  --color-teal-200: #DBFAFD;
  --color-teal-300: #B1EAEF;
  --color-teal-400: #78D2DB;
  --color-teal-500: #2CA9B4;
  --color-teal-600: #157E88;
  --color-green-100: #ECFFF7;
  --color-green-200: #DAFDEF;
  --color-green-300: #B4F2D9;
  --color-green-400: #84DDB9;
  --color-green-500: #5FB693;
  --color-green-600: #11704A;
  --color-gold-100: #FFF8EC;
  --color-gold-200: #F4E8D2;
  --color-gold-300: #EED6AE;
  --color-gold-400: #E1C697;
  --color-gold-500: #D7B170;
  --color-gold-600: #875D15;
  --color-red-100: #FFF5F6;
  --color-red-200: #FFD9DC;
  --color-red-300: #FF7D88;
  --color-red-400: #F15966;
  --color-red-500: #BB0011;
  --color-red-600: #841923;
  --color-pink-400: #D95E80;
  --color-pink-500: #D03661;
  --color-pink-600: #A9284C;
  --color-pink-700: #801E39;
  --color-lime-300: #C1E27E;
  --color-lime-400: #AFDA58;
  --color-lime-500: #9BD02F;
  --color-white: #fff;
  --color-black: #000;
  --rounded-default: 4px;
  --rounded-button: 4px;
  --rounded-badge: 3px;
  --font-family-body: Inter;
  --font-family-headline: GT Flexa;
  --font-size-root: 16px;
  --font-size-2xs: 0.5rem;
  --font-size-xs: 0.625rem;
  --font-size-sm: 0.75rem;
  --font-size-body: 0.875rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.5rem;
  --font-size-3xl: 1.75rem;
  --font-size-4xl: 2rem;
  --font-size-5xl: 2.5rem;
  --font-size-6xl: 3.5rem;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --leading-normal: 1.5;
  --leading-tight: 1.1;
  --leading-snug: 1.25;
  --leading-relaxed: 1.75;
  --leading-loose: 2;
  --leading-body: 1.5;
  --shadow-100: 0 1px 2px 0 rgba(0,0,0,0.25);
  --shadow-200: 0 4px 8px -2px rgba(0,0,0,0.25);
  --shadow-300: 0 8px 16px -4px rgba(0,0,0,0.25);
  --shadow-400: 0 12px 24px -6px rgba(0,0,0,0.25);
  --shadow-500: 0 20px 32px -8px rgba(0,0,0,0.25);
  --shadow-100-up: 0 -1px 4px 0 rgba(0,0,0,0.25);
  --shadow-200-up: 0 -4px 8px -2px rgba(0,0,0,0.25);
  --shadow-300-up: 0 -8px 16px -4px rgba(0,0,0,0.25);
  --shadow-400-up: 0 -12px 24px -6px rgba(0,0,0,0.25);
  --shadow-500-up: 0 -20px 32px -8px rgba(0,0,0,0.25);
  --size-base: 4px;
  --max-width-content: 1024px;
  --cta-height: 40px;
  --rounded-pill: 100px;
  --spacing-0: 0px;
  --spacing-12: 12px;
  --spacing-24: 24px;
  --spacing-32: 32px;
  --spacing-40: 40px;
  --spacing-56: 56px;
  --spacing-vertical-mobile-sm: 12px;
  --spacing-vertical-mobile-md: 32px;
  --spacing-vertical-mobile-lg: 40px;
  --spacing-vertical-desktop-sm: 12px;
  --spacing-vertical-desktop-md: 32px;
  --spacing-vertical-desktop-lg: 56px;
}
