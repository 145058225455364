:root {
  --content-section-container-border-bottom: 1px solid var(--color-gray-200);
  --child-wrapper-padding: var(--space-8x) var(--space-5x);

  --content-section-body-open-padding: var(--space-8x) var(--space-5x)
    var(--space-10x) var(--space-5x);

  @media (--screen-md) {
    --content-section-body-open-padding: var(--space-8x) var(--space-5x)
      var(--space-8x) var(--space-5x);
  }
}
