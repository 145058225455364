:root {
  --info-section-header-open-background-color: var(--color-indigo-500);
  --info-section-header-closed-background-color: var(--color-white);
  --info-section-description-closed-color: var(--color-gray-500);
  --info-section-description-open-color: var(--color-white);
  --info-section-heading-margin-bottom: var(--space-3x);
  --info-section-heading-font-family: var(--font-family-body);
  --info-section-heading-open-color: var(--color-white);
  --info-section-icon-open-color: var(--color-indigo-500);
  --info-section-icon-closed-color: var(--color-white);
  --info-section-icon-wrapper-closed-background-color: var(--color-indigo-500);
  --info-section-icon-wrapper-open-background-color: var(--color-gray-100);
  --info-section-rating-star-backdrop-color: var(--color-indigo-300);
  --info-section-rating-star-color: var(--color-white);
  --info-section-rating-text-color: var(--color-white);
  --info-section-rating-source-divider-color: var(--color-indigo-400);
  --info-section-rating-source-color: var(--color-white);
  --info-section-button-display: inline-block;
  --info-section-header-padding: var(--space-5x);
  --info-section-button-position: absolute;
  --info-section-button-height: 100%;
  --info-section-button-width: 100%;
  --info-section-header-align-items: initial;
  --info-section-header-flex-direction: column;
}
