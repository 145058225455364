/**
 * Do not edit directly
 * Generated on Fri, 04 Apr 2025 20:41:59 GMT
 */

:root, ::before, ::after {
  --color-indigo-100: #F4F6FF;
  --color-indigo-200: #E7EBFF;
  --color-indigo-300: #ADBBFF;
  --color-indigo-400: #7E93FB;
  --color-indigo-500: #4561EC;
  --color-indigo-600: #22389C;
  --color-indigo-700: #061558;
  --color-gray-100: #F5F5F6;
  --color-gray-200: #E5E5E8;
  --color-gray-300: #BEBEC4;
  --color-gray-400: #8F8F95;
  --color-gray-500: #58585A;
  --color-gray-600: #373839;
  --color-gray-700: #17181C;
  --color-violet-100: #F9F5FF;
  --color-violet-200: #EFE3FF;
  --color-violet-300: #DBBFFF;
  --color-violet-400: #B67EFF;
  --color-violet-500: #8D4BDF;
  --color-violet-600: #582797;
  --color-blue-100: #F2F8FE;
  --color-blue-200: #C7E6FF;
  --color-blue-300: #AEDAFD;
  --color-blue-400: #77BFF7;
  --color-blue-500: #298EDE;
  --color-blue-600: #1569AA;
  --color-teal-100: #EFFEFF;
  --color-teal-200: #DBFAFD;
  --color-teal-300: #B1EAEF;
  --color-teal-400: #78D2DB;
  --color-teal-500: #2CA9B4;
  --color-teal-600: #157E88;
  --color-green-100: #ECFFF7;
  --color-green-200: #DAFDEF;
  --color-green-300: #B4F2D9;
  --color-green-400: #84DDB9;
  --color-green-500: #5FB693;
  --color-green-600: #11704A;
  --color-gold-100: #FFF8EC;
  --color-gold-200: #F4E8D2;
  --color-gold-300: #EED6AE;
  --color-gold-400: #E1C697;
  --color-gold-500: #D7B170;
  --color-gold-600: #875D15;
  --color-red-100: #FFF5F6;
  --color-red-200: #FFD9DC;
  --color-red-300: #FF7D88;
  --color-red-400: #F15966;
  --color-red-500: #BB0011;
  --color-red-600: #841923;
  --color-pink-400: #D95E80;
  --color-pink-500: #D03661;
  --color-pink-600: #A9284C;
  --color-pink-700: #801E39;
  --color-lime-300: #C1E27E;
  --color-lime-400: #AFDA58;
  --color-lime-500: #9BD02F;
  --color-white: #fff;
  --color-black: #000;
  --rounded-default: 4px;
  --rounded-button: 4px;
  --rounded-badge: 3px;
  --font-family-body: Inter;
  --font-family-headline: GT Flexa;
  --font-size-root: 16px;
  --font-size-2xs: 0.5rem;
  --font-size-xs: 0.625rem;
  --font-size-sm: 0.75rem;
  --font-size-body: 0.875rem;
  --font-size-md: 1rem;
  --font-size-lg: 1.125rem;
  --font-size-xl: 1.25rem;
  --font-size-2xl: 1.5rem;
  --font-size-3xl: 1.75rem;
  --font-size-4xl: 2rem;
  --font-size-5xl: 2.5rem;
  --font-size-6xl: 3.5rem;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --leading-normal: 1.5;
  --leading-tight: 1.1;
  --leading-snug: 1.25;
  --leading-relaxed: 1.75;
  --leading-loose: 2;
  --leading-body: 1.5;
  --shadow-100: 0 1px 2px 0 rgba(0,0,0,0.25);
  --shadow-200: 0 4px 8px -2px rgba(0,0,0,0.25);
  --shadow-300: 0 8px 16px -4px rgba(0,0,0,0.25);
  --shadow-400: 0 12px 24px -6px rgba(0,0,0,0.25);
  --shadow-500: 0 20px 32px -8px rgba(0,0,0,0.25);
  --shadow-100-up: 0 -1px 4px 0 rgba(0,0,0,0.25);
  --shadow-200-up: 0 -4px 8px -2px rgba(0,0,0,0.25);
  --shadow-300-up: 0 -8px 16px -4px rgba(0,0,0,0.25);
  --shadow-400-up: 0 -12px 24px -6px rgba(0,0,0,0.25);
  --shadow-500-up: 0 -20px 32px -8px rgba(0,0,0,0.25);
  --size-base: 4px;
  --max-width-content: 1024px;
  --cta-height: 40px;
  --rounded-pill: 100px;
  --spacing-0: 0px;
  --spacing-12: 12px;
  --spacing-24: 24px;
  --spacing-32: 32px;
  --spacing-40: 40px;
  --spacing-56: 56px;
  --spacing-vertical-mobile-sm: 12px;
  --spacing-vertical-mobile-md: 32px;
  --spacing-vertical-mobile-lg: 40px;
  --spacing-vertical-desktop-sm: 12px;
  --spacing-vertical-desktop-md: 32px;
  --spacing-vertical-desktop-lg: 56px;
}

@layer tokens {
  /* prettier-ignore */
  :root, ::before, ::after {
    /* spacing scale */
    --space-none: 0;        /* 0px */
    --space-px: 1px;        /* 1px */
    --space-0-5x: 0.125rem; /* 2px */
    --space-1x: 0.25rem;    /* 4px */
    --space-1-5x: 0.375rem; /* 6px */
    --space-2x: 0.5rem;     /* 8px */
    --space-2-5x: 0.625rem; /* 10px */
    --space-3x: 0.75rem;    /* 12px */
    --space-3-5x: 0.875rem; /* 14px */
    --space-4x: 1rem;       /* 16px */
    --space-4-5x: 1.125rem; /* 18px */
    --space-5x: 1.25rem;    /* 20px */
    --space-5-5x: 1.375rem; /* 22px */
    --space-6x: 1.5rem;     /* 24px */
    --space-6-5x: 1.625rem; /* 26px */
    --space-7x: 1.75rem;    /* 28px */
    --space-7-5x: 1.875rem; /* 28px */
    --space-8x: 2rem;       /* 32px */
    --space-9x: 2.25rem;    /* 36px */
    --space-10x: 2.5rem;    /* 40px */
    --space-10-5x: 2.625rem;/* 42px */
    --space-11x: 2.75rem;   /* 44px */
    --space-12x: 3rem;      /* 48px */
    --space-14x: 3.5rem;    /* 56px */
    --space-15x: 3.75rem;   /* 60px */
    --space-16x: 4rem;      /* 64px */
    --space-18x: 4.5rem;    /* 72px */
    --space-20x: 5rem;      /* 80px */
    --space-24x: 6rem;      /* 96px */
    --space-28x: 7rem;      /* 112px */
    --space-29x: 7.25rem;   /* 116px */
    --space-31x: 7.75rem;   /* 124px */
    --space-32x: 8rem;      /* 128px */
    --space-36x: 9rem;      /* 144px */
    --space-40x: 10rem;     /* 160px */
    --space-44x: 11rem;     /* 176px */
    --space-48x: 12rem;     /* 192px */
    --space-52x: 13rem;     /* 208px */
    --space-56x: 14rem;     /* 224px */
    --space-60x: 15rem;     /* 240px */
    --space-64x: 16rem;     /* 256px */
    --space-72x: 18rem;     /* 288px */
    --space-80x: 20rem;     /* 320px */
    --space-96x: 24rem;     /* 384px */

    /* z-index levels */
    --z-index-0: 0;
    --z-index-1x: 1;
    --z-index-2x: 2;
    --z-index-3x: 3;
    --z-index-4x: 4;
    --z-index-5x: 5;
    --z-index-6x: 6;
    --z-index-7x: 7;
    --z-index-auto: auto;

    /* border radii */
    --border-radius-none: 0;
    --border-radius-1x: var(--space-0-5x);
    --border-radius-2x: var(--space-1x);
    --border-radius-3x: var(--space-1-5x);
    --border-radius-4x: var(--space-2x);
    --border-radius-5x: var(--space-2-5x);
    --border-radius-6x: var(--space-3x);
    --border-radius-7x: var(--space-3-5x);
    --border-radius-8x: var(--space-4x);
    --border-radius-full: 100px;
    --border-radius-circle: 100%;

    /* shadows */
    /* stylelint-disable color-function-notation */
    --shadow-600: 0 4px 8px -2px rgba(0, 0, 0, 10%);

    --color-focus-outline: var(--color-green-200);
    --color-focus-shadow: var(--color-green-600);
  }
}

/* should really be (hover: hover) and (any-pointer: coarse) but
can't due to bug
https://issues.chromium.org/issues/40855702
Demo of bug: https://codepen.io/kylegordy/pen/abOLbXV
*/

/* should really be (hover: hover) and (any-pointer: fine)
but can't due to bug
https://issues.chromium.org/issues/40855702
Demo of bug: https://codepen.io/kylegordy/pen/abOLbXV
*/

@layer reset {
  /* Use a more-intuitive box-sizing model */
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  /* Remove default margin */
  * {
    margin: 0;
  }

  /* Allow percentage-based heights in the application */
  html,
  body {
    height: 100%;
  }

  /* Improve text rendering and readability */
  body {
    font-family: var(--font-family-body);
    line-height: var(--leading-body);
    -webkit-font-smoothing: antialiased;
  }

  /* Improve media defaults */
  img,
  picture,
  video,
  canvas {
    display: block;
    max-width: 100%;
  }

  /* Remove built-in form typography styles */
  input,
  button,
  textarea,
  select {
    font: inherit;
  }

  /* Avoid text overflows */
  p,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    overflow-wrap: break-word;
  }

  /* Create a root stacking context */
  /* stylelint-disable-next-line selector-id-pattern */
  #root,
  #__next {
    isolation: isolate;
  }

  /* Hide native calendar icons on date inputs. */
  input[type='date']::-webkit-inner-spin-button,
  input[type='date']::-webkit-calendar-picker-indicator {
    opacity: 0;
  }

  /* Prevent zoom-in when user quickly taps button (such as for the gallery navigation buttons) */
  button,
  input[type='button'] {
    touch-action: manipulation;
  }

  /* Reset button styles */
  button {
    appearance: none;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  /* Don't show webkit's blue autofill background */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    box-shadow: 0 0 0 30px white inset;
  }

  button:focus-visible,
  button:focus-visible:focus,
  input[type='checkbox']:focus-visible,
  input[type='radio']:focus-visible,
  input[type='submit']:focus-visible,
  select:focus-visible,
  form:focus-visible,
  *[role='button']:focus-visible,
  *[role='tabpanel']:focus-visible,
  *[role='listbox']:focus-visible,
  .toggleButton:focus-visible,
  a:focus-visible,
  input[type='radio']:focus-visible + label {
    outline: 2px solid var(--color-focus-outline);
    box-shadow: 0 0 0 4px var(--color-focus-shadow);
    outline-offset: 0;
  }

  button[data-headlessui-state='open']:focus-visible {
    outline: none;
    box-shadow: none;
  }

  /* This applies to the Tab elements deemed for non accessibilities users only */
  .mouseVisited[role='tablist'] > button[role='tab'] {
    outline: none;
    box-shadow: none;
  }

  dialog {
    border: 0;
  }

  /* https://github.com/whatwg/html/issues/7732 */
  body:has(dialog[open]) {
    overflow: hidden;
  }
}

/* stylelint-disable */

@layer utilities {
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  .not-sr-only {
    position: static;
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: visible;
    clip: auto;
    white-space: normal;
  }
}

@media (min-width: 375px) {
    .xs\:not-sr-only {
      position: static;
      width: auto;
      height: auto;
      padding: 0;
      margin: 0;
      overflow: visible;
      clip: auto;
      white-space: normal;
    }
}

@media (min-width: 600px) {
    .sm\:not-sr-only {
      position: static;
      width: auto;
      height: auto;
      padding: 0;
      margin: 0;
      overflow: visible;
      clip: auto;
      white-space: normal;
    }
}

@media (min-width: 768px) {
    .md\:not-sr-only {
      position: static;
      width: auto;
      height: auto;
      padding: 0;
      margin: 0;
      overflow: visible;
      clip: auto;
      white-space: normal;
    }
}

@media (min-width: 1024px) {
    .lg\:not-sr-only {
      position: static;
      width: auto;
      height: auto;
      padding: 0;
      margin: 0;
      overflow: visible;
      clip: auto;
      white-space: normal;
    }
}

@media (min-width: 1126px) {
    .xl\:not-sr-only {
      position: static;
      width: auto;
      height: auto;
      padding: 0;
      margin: 0;
      overflow: visible;
      clip: auto;
      white-space: normal;
    }
}

:root {
  --aerial-view-error-card-background-color: var(--color-indigo-100);
  --aerial-view-error-card-color: var(--color-indigo-500);
}

:root {
  --amenity-callout-container-width: 100%;
  --amenity-callout-container-background-color: var(--color-indigo-100);
  --amenity-callout-icon-color: var(--color-indigo-500);
}

:root {
  --anchor-nav-underline-color: var(--color-indigo-500);
  --anchor-nav-border-bottom: var(--space-px) solid var(--color-gray-200);
  --anchor-nav-top: 76px;
  --anchor-nav-opened-mobile-banner-top: 44px;
}

:root {
  --anchor-direction: initial;
  --anchor-display: inline;
  --anchor-font-size: inherit;
  --anchor-gap: 0;
  --anchor-padding: 0;
  --anchor-placement: inherit;
  --anchor-position: static;
  --anchor-text-color-active: var(--color-indigo-700);
  --anchor-text-color-hover: var(--color-indigo-600);
  --anchor-text-color-default: var(--color-indigo-500);
  --anchor-text-color-dark-active: var(--color-blue-500);
  --anchor-text-color-dark-hover: var(--color-blue-300);
  --anchor-text-color-dark-default: var(--color-indigo-400);
  --anchor-text-decoration-hover: underline;
  --anchor-text-decoration-default: none;
  --anchor-text-alignment: inherit;
  --anchor-weight: var(--font-weight-semibold);
  --anchor-z-index: auto;
  --anchor-text-color-disabled: var(--color-gray-300);
  --anchor-align-self: unset;
  --anchor-line-height: normal;
}

:root {
  --close-app-banner-button-color: var(--color-indigo-500);
  --app-banner-position: relative;
}

:root {
  --average-rent-table-header-display: block;
  --average-rent-table-header-cell-color: var(--color-black);
  --average-rent-table-even-row-background: var(--color-gray-100);
  --average-rent-table-default-text-color: var(--color-gray-500);
}

:root {
  --backdrop-background-color: var(--color-black);
  --backdrop-background-opacity: 0.3;
  --backdrop-background-display: block;
}

:root {
  --brand-divider-margin: 0 0 var(--space-9x) 0;
  --brand-divider-background-dark: var(--color-indigo-400);
  --brand-divider-background-light: var(--color-indigo-500);
  --brand-divider-width: var(--space-10x);
  --brand-divider-height: var(--space-2x);
}

:root {
  --breadcrumbs-text-font-weight: var(--font-weight-normal);
  --breadcrumbs-text-font-size: var(--font-size-sm);
  --breadcrumbs-headline-color: var(--color-gray-500);
  --breadcrumbs-headline-display: inline-flex;
  --breadcrumbs-links-display: inline-flex;
  --breadcrumbs-chevron-display: inline;
}

:root {
  --budget-tooltip-anchor-color: var(--color-indigo-400);
  --budget-tooltip-close-icon-color: var(--color-indigo-400);
}

:root {
  --button-display: inline-flex;
  --button-gap: var(--space-2x);
  --button-direction: row;
  --button-placement: center;
  --button-padding: 0 var(--space-4x);
  --button-margin: 0;
  --button-font-size: 1rem;
  --button-font-weight: var(--font-weight-semibold);
  --button-outlined-border-color: var(--color-indigo-500);
  --button-outlined-border-hover-color: var(--color-indigo-500);

  /* Pill variables */
  --button-pill-border-color: var(--color-indigo-500);
  --button-pill-color-base: var(--color-indigo-500);
  --button-pill-color-hover: var(--color-white);
  --button-pill-background-hover: var(--color-indigo-500);

  --button-background-color-base: var(--color-indigo-500);
  --button-background-color-hover: var(--color-indigo-600);
  --button-background-color-active: var(--color-indigo-600);
  --button-min-width: inherit;
  --button-width: auto;
  --button-height: 40px;
  --button-height-sm: 30px;
  --button-height-md: 40px;
  --button-height-lg: 46px;
  --button-outlined-color-hover: var(--color-indigo-600);
  --button-outlined-background-color: var(--color-white);
  --button-outlined-base-color: var(--color-indigo-500);
  --button-outlined-background-color-hover: transparent;
  --button-outlined-font-weight: var(--font-weight-semibold);
  --button-primary-disabled-color: var(--color-gray-400);
  --button-primary-disabled-background-color: var(--color-gray-100);
  --button-filter-color: var(--color-gray-500);
  --button-filter-color-hover: var(--color-gray-500);
  --button-filter-border: 1px solid var(--color-gray-200);
  --button-filter-border-bottom: 1px solid var(--color-gray-200);
  --button-rounded-border-radius: var(--space-1x);
  --button-radius-top: 0 0;
  --button-radius-bottom: 0 0;
  --button-white-space: nowrap;
  --button-tertiary-background-color: var(--color-gray-100);
  --button-tertiary-background-color-hover: var(--color-gray-200);
  --button-tertiary-background-color-active: var(--color-gray-300);
  --button-tertiary-color: var(--color-black);
  --button-tertiary-color-hover: var(--color-black);
  --button-tertiary-color-active: var(--color-black);
}

:root {
  --calendar-border: 1px solid var(--color-gray-100);
  --calendar-padding: 5px;
  --calendar-margin: 0 0 var(--space-4x);
  --calendar-border-radius: 0.25rem;
  --calendar-color: var(--color-gray-500);
  --calendar-selected-day-bg-color: var(--color-indigo-500);
}

:root {
  --card-icon-background: var(--color-white);
  --card-icon-color: var(--color-indigo-500);
  --card-icon-height: var(--space-9x);
  --card-icon-width: var(--space-9x);
}

:root,
::before,
::after {
  --checkbox-bg-color: var(--color-white);
  --checkbox-bg-color-filled: var(--color-indigo-500);
  --checkbox-border-color: var(--color-black);
  --checkbox-border-color-filled: var(--color-indigo-500);
  --checkbox-border-width: 2px;
  --checkbox-size: 16px;
  --checkbox-text-color-filled: var(--color-white);
  --checkbox-text-color: var(--color-white);
  --checkbox-pill-background-color: var(--color-teal-600);
  --checkbox-pill-color: var(--color-white);
}

:root {
  --contact-property-mobile-background-color: var(--color-indigo-100);
}

:root {
  --container-width: 100%;
  --container-max-width: 1200px;
  --container-padding: 0;
}

:root {
  --content-section-container-border-bottom: 1px solid var(--color-gray-200);
  --child-wrapper-padding: var(--space-8x) var(--space-5x);

  --content-section-body-open-padding: var(--space-8x) var(--space-5x)
    var(--space-10x) var(--space-5x);
}@media (min-width: 768px) {:root {
    --content-section-body-open-padding: var(--space-8x) var(--space-5x)
      var(--space-8x) var(--space-5x)
}
}

:root {
  --current-location-button-spinner-color: var(--color-indigo-500);
  --current-location-button-spinner-size: var(--space-6x);
  --current-location-button-spinner-width: 3px;
  --current-location-icon-size: var(--space-6x);
}

:root {
  --date-input-font-size: var(--font-size-body);
  --date-input-height: 45px;
  --date-input-border: 1px solid var(--color-gray-300);
  --date-input-border-radius: 4px;
  --date-input-color: var(--color-gray-500);
  --date-input-error-message-color: var(--color-red-500);
}

:root {
  --day-time-picker-card-slot-background-color: var(--color-white);
  --day-time-picker-card-slot-active-color: var(--color-indigo-500);
  --day-time-picker-card-date-slot-text-color-day: var(--color-gray-500);
  --day-time-picker-card-date-slot-text-color-date: var(--color-black);
}

:root {
  --badge-background-color: var(--color-indigo-500);
  --badge-icon-color: var(--color-teal-600);
}

:root {
  --divider-width: auto;
  --divider-margin: var(--space-8x) 0;
  --divider-display: block;
  --divider-color: var(--color-gray-200);
}

:root {
  --drawer-menu-trigger-color: var(--color-white);
  --drawer-menu-trigger-font-size: var(--font-size-xl);
  --drawer-menu-trigger-padding: 0 var(--space-6x);
  --drawer-menu-link-weight: bold;
  --drawer-menu-link-color: var(--color-indigo-500);
  --drawer-menu-link-hover-color: var(--color-indigo-600);
  --drawer-menu-link-background-color: var(--color-white);
  --drawer-menu-link-background-hover-color: var(--color-indigo-100);
  --drawer-menu-divider-margin-top: var(--space-3x);
  --drawer-menu-divider-border: 1px solid var(--color-gray-200);
  --drawer-menu-non-link-color: 1px solid var(--color-gray-500);
  --drawer-menu-non-link-background-color: var(--color-white);
}

:root {
  --enhanced-lead-form-header-margin: 0 0 var(--space-8x) 0;
  --enhanced-lead-form-header-padding: initial;
}

:root {
  --enhanced-lead-questions-form-display: initial;
  --enhanced-lead-questions-form-flex: initial;
  --enhanced-lead-questions-form-flex-direction: initial;
  --enhanced-lead-questions-footer-padding: initial;
  --enhanced-lead-questions-footer-margin: initial;
  --enhanced-lead-questions-content-wrapper-padding: initial;
}

:root {
  --email-capture-text-align: left;
  --email-capture-modal-width: 654px;
  --email-capture-gap: var(--space-3x);
  --email-capture-form-layout-inner-gap: var(--space-3x);
  --email-capture-form-section-width: 50%;
  --email-capture-flex-direction: column;
  --email-capture-flex-direction-screen-md: column;
  --email-capture-form-layout-flex-direction: row;
  --email-capture-form-layout-flex-direction-screen-md: row;
  --email-capture-form-layout-inner-flex-direction: row;
  --email-capture-form-layout-gap: var(--space-3x);
  --email-capture-padding: var(--space-6x) var(--space-4x) var(--space-4x);
  --email-capture-border: 1px solid var(--color-gray-200);
  --email-capture-border-left: 7px solid var(--color-indigo-500);
  --email-capture-form-button-disabled-color: var(--color-indigo-600);
  --email-capture-form-button-disabled-background-color: var(
    --color-indigo-200
  );
}

:root {
  --error-page-background: var(--color-black);
  --error-page-heading-color: var(--color-white);
  --error-page-text: var(--color-white);
  --error-page-link-color: var(--color-indigo-400);
  --search-input-border-width: 0;
  --search-input-border-color: transparent;
  --error-page-listing-card-gradient: var(--color-black);
}

:root {
  --expanded-list-closed-before-content: unset;
  --expanded-list-closed-before-padding: unset;
  --expanded-list-open-list-flex-wrap: unset;
  --expanded-list-open-list-padding: unset;
  --expanded-list-flex-wrap: unset;
  --expanded-list-width: unset;
  --expanded-list-padding: 0;
  --expanded-list-margin: 0;
  --expanded-list-display: flex;
  --expanded-list-flex-direction: unset;
  --expanded-list-list-style: none;
  --expanded-list-overflow-x: unset;
  --expanded-list-overflow-y: unset;
  --expanded-list-item-padding: 0;
  --expanded-list-item-margin: 0;
  --expanded-list-item-display: flex;
  --expanded-list-item-flex-direction: unset;
  --expanded-list-item-line-height: unset;
  --expanded-list-item-font-size: var(--font-size-body);
  --expanded-list-button-width: unset;
  --expanded-list-button-white-space: unset;
  --expanded-list-button-border-radius: unset;
  --expanded-list-button-background-color: unset;
  --expanded-list-button-color: unset;
  --expanded-list-button-padding: unset;
  --expanded-list-button-font-size: unset;
  --expanded-list-row-gap: 0;
  --expanded-list-justify-content: unset;
  --expanded-list-item-width: unset;
}

:root {
  --filter-card-flex-direction: row;
  --filter-card-title-order: initial;
  --filter-card-image-order: initial;
  --filter-card-title-width: 50%;
  --filter-card-image-width: 50%;
  --filter-card-title-padding-top: var(--space-8x);
  --filter-card-title-padding-bottom: var(--space-5x);
  --filter-card-image-height: 250px;
  --filter-card-image-padding-top: var(--space-7x);
  --filter-card-apartment-count-padding: var(--filter-card-title-padding-top)
    var(--space-6x) var(--filter-card-title-padding-bottom);

  --filter-card-toggle-button-border-width: 2px;

  --filter-card-toggle-button-color: var(--color-indigo-500);
  --filter-card-toggle-button-hover-color: var(--color-indigo-600);

  --filter-card-toggle-button-border-color: var(--color-indigo-500);
  --filter-card-toggle-button-hover-border-color: var(--color-indigo-600);
  --filter-card-toggle-button-border-color-selected: var(--color-indigo-500);
  --filter-card-toggle-button-selected-hover-border-color: var(
    --color-indigo-600
  );

  --filter-card-toggle-button-hover-background-color: var(--color-white);
  --filter-card-toggle-button-background-color-selected: var(
    --color-indigo-500
  );
  --filter-card-toggle-button-background-color-selected-hover: var(
    --color-indigo-600
  );
  --filter-card-cta-border-radius: var(--border-radius-full);
}

:root {
  --floor-plan-modal-heading-background: var(--color-indigo-500);
  --floor-plan-modal-header-color: var(--color-white);
  --floor-plan-modal-heading-color: var(--color-white);
  --floor-plan-modal-button-text-color-base: var(--color-white);
  --floor-plan-modal-button-text-color-hover: var(--color-white);
  --floor-plan-modal-lead-form-background: var(--color-indigo-100);
}

:root {
  --floor-plan-unit-background-hover: var(--color-indigo-100);
  --floor-plan-price-drop-badge-background: var(--color-teal-600);
}

:root {
  --footer-margin: 0;
  --footer-background: var(--color-indigo-100);
  --footer-color: var(--color-black);
  --footer-heading-label-color: var(--color-black);
  --footer-social-icon-background: var(--color-indigo-500);
  --footer-social-icon-background-hover: var(--color-indigo-600);
  --footer-social-icon-background-active: var(--color-indigo-600);
  --footer-social-icon-color: var(--color-white);
  --footer-social-icon-size: 32px;
  --footer-social-links-justify-content: center;
  --footer-social-links-gap: var(--space-6x);
  --footer-logo-color: var(--color-black);
  --footer-anchor-text-color: var(--color-indigo-500);
  --footer-anchor-text-color-hover: var(--color-indigo-600);
  --footer-anchor-text-color-active: var(--color-indigo-400);
  --footer-link-section-border: 1px solid var(--color-gray-200);
  --sticky-footer-background: var(--color-indigo-500);
  --footer-link-section-chevron-icon-color: var(--color-indigo-400);
  --footer-logo-max-width: 162px;
}

.footerDark {
  --footer-background: var(--color-black);
  --footer-color: var(--color-white);
  --footer-heading-label-color: var(--color-white);
  --footer-anchor-text-color: var(--color-indigo-400);
  --footer-anchor-text-color-hover: var(--color-indigo-300);
  --footer-anchor-text-color-active: var(--color-indigo-500);
  --footer-social-icon-background-hover: var(--color-indigo-300);
  --footer-social-icon-color: var(--color-white);
  --footer-logo-color: var(--color-white);
  --footer-link-section-border: 1px solid var(--color-gray-500);
}

:root {
  --deals-border: unset;
  --deals-heading-font-size: var(--font-size-lg);
  --deals-heading-label-color: var(--color-white);
  --summary-pets-border: unset;
  --pet-icon-color: unset;
  --accomodation-info-container-margin-bottom: 10px;
  --summary-mgmt-co-name-display: block;
  --summary-sqft-display: none;
}@media (min-width: 1024px) {:root {
    --summary-sqft-display: inline
}
}

:root {
  --form-error-background-color: var(--color-red-200);
  --form-error-text-color: var(--color-red-500);
  --form-error-border: 1px solid var(--color-red-500);
}

:root {
  --frameable-image-display: block;
}

:root {
  --gallery-modal-header-favorite-share-button-background-color: var(
    --color-indigo-100
  );
  --gallery-modal-header-favorite-share-button-hover-background-color: var(
    --color-indigo-200
  );
  --gallery-modal-header-favorite-share-button-color: var(--color-indigo-500);
  --gallery-modal-header-favorite-share-button-hover-color: var(
    --color-indigo-500
  );
}

:root {
  --gallery-reaction-active-color: var(--color-white);
  --gallery-reaction-hover-color: var(--color-white);
  --gallery-reaction-base-color: var(--color-white);
  --gallery-reaction-background-color: rgb(0 0 0 / 50%);
  --gallery-reaction-filled-background-color: var(--color-white);
  --gallery-pill-background-color: var(--color-indigo-500);
  --gallery-pill-border-color: var(--color-indigo-400);
  --gallery-pill-box-shadow: initial;
  --gallery-pill-color: var(--color-white);
  --gallery-reaction-arrow-button-color: var(--color-indigo-500);
  --gallery-pill-arrow-border-color: var(--color-indigo-400);
  --gallery-reaction-arrow-button-hover-color: var(--color-indigo-600);
  --gallery-carousel-slide-border-radius: var(--border-radius-2x);
  --gallery-zindex: initial;
}

:root {
  --heading-with-heading-font-text-transform: none;
  --heading-with-heading-font-font-weight: 400;
  --heading-with-body-font-text-transform: none;
  --heading-with-body-font-font-weight: 600;
  --heading-text-transform: none;
  --heading-text-align: inherit;
  --heading-border-bottom: 0;
  --heading-font-size: var(--font-size-body);
  --heading-font-weight: 400;
  --heading-padding: 0;
  --heading-margin: 0;
  --heading-color: var(--color-black);
  --heading-letter-spacing: -0.02em;
  --heading-line-height: var(--leading-tight);
}

:root {
  --highlights-highlight-color: var(--color-gray-600);
  --highlights-highlight-star-color: var(--color-indigo-500);
  --highlights-highlight-star-margin: 0 var(--space-1x) 0 0;
  --highlights-highlight-star-width: var(--space-4x);
  --highlights-highlight-star-height: var(--space-4x);
  --highlights-highlight-item-width: fit-content;
  --highlights-highlight-item-height: unset;
  --highlights-highlight-item-white-space: nowrap;
  --highlights-highlight-item-display: flex;
  --highlights-highlight-item-align-items: center;
  --highlights-highlight-item-border-radius: var(--border-radius-full);
  --highlights-highlight-item-background-color: var(--color-gray-100);
  --highlights-highlight-item-color: var(--highlights-highlight-color);
  --highlights-highlight-item-padding: var(--space-1x) var(--space-3x);
}

:root {
  --home-page-background: var(--color-black);
  --home-page-color: var(--color-white);
  --home-page-container-max-width: 1325px;
  --home-page-font-size: var(--font-size-body);
  --home-page-heading-font-size-mobile: var(--font-size-5xl);
  --home-page-heading-font-size-desktop: var(--font-size-6xl);
  --home-page-heading-font-weight-desktop: 500;
  --home-page-heading-font-weight-mobile: 500;
  --home-page-overflow: hidden;
}

:root {
  --home-page-seo-links-section-anchor-text-color: var(--color-indigo-400);
  --home-page-seo-links-section-anchor-text-color-hover: var(
    --color-indigo-300
  );
  --home-page-seo-links-section-anchor-text-color-active: var(
    --color-indigo-500
  );
}

:root {
  --horizontal-scroll-arrow-button-color: var(--color-indigo-500);
  --horizontal-scroll-arrow-button-hover-color: var(--color-indigo-600);
  --horizontal-scroll-arrow-button-position: absolute;
  --horizontal-scroll-arrow-button-margin: -14px 0 0 0;
  --horizontal-scroll-arrow-button-hidden-display: none;
  --horizontal-scroll-arrow-button-direction: -10px;
  --horizontal-scroll-arrow-button-box-shadow: var(--shadow-200);
  --horizontal-scroll-arrow-button-dimensions: var(--space-7x);
  --horizontal-scroll-arrow-button-top: 0;
  --horizontal-scroll-arrow-button-icon-dimensions: var(--space-4x);
}

:root {
  --horizontal-scroll-container-position: relative;
  --horizontal-scroll-container-margin: initial;

  --horizontal-scroll-list-gap: 0;
  --horizontal-scroll-list-display: flex;
  --horizontal-scroll-list-overflow-x: auto;
  --horizontal-scroll-list-align-items: center;
  --horizontal-scroll-list-position: relative;
  --horizontal-scroll-list-height: auto;
  --horizontal-scroll-list-scroll-behavior: smooth;

  --horizontal-scroll-gradient-background-color: var(--color-white);
}

:root {
  --icon-button-display: inline-block;
  --icon-button-icon-display: flex;
  --icon-button-width: auto;
  --icon-button-max-width: auto;
  --icon-button-gap: var(--space-1x);
  --icon-button-flex: initial;
}

:root {
  --icon-cta-border-radius: var(--border-radius-full);
  --icon-cta-active-color: var(--color-indigo-600);
  --icon-cta-hover-color: var(--color-indigo-400);
  --icon-cta-base-color: var(--color-indigo-500);
  --icon-cta-filled-color: var(--color-indigo-500);
  --icon-cta-background-color: var(--color-white);
  --icon-cta-hover-background-color: var(--color-white);
  --icon-cta-active-background-color: inherit;
  --icon-cta-width: var(--space-10x);
  --icon-cta-height: var(--space-10x);
  --icon-cta-font-size: var(--font-size-xl);
  --icon-cta-margin: unset;
  --icon-cta-padding: unset;
  --icon-cta-position: unset;
  --icon-cta-z-index: unset;
  --icon-cta-top: unset;
  --icon-cta-right: unset;
}

:root {
  --icon-cta-active-color: var(--color-indigo-600);
  --icon-cta-hover-color: var(--color-indigo-400);
  --icon-cta-base-color: var(--color-indigo-500);
  --icon-cta-filled-color: var(--color-indigo-500);
}

:root {
  --info-section-header-open-background-color: var(--color-indigo-500);
  --info-section-header-closed-background-color: var(--color-white);
  --info-section-description-closed-color: var(--color-gray-500);
  --info-section-description-open-color: var(--color-white);
  --info-section-heading-margin-bottom: var(--space-3x);
  --info-section-heading-font-family: var(--font-family-body);
  --info-section-heading-open-color: var(--color-white);
  --info-section-icon-open-color: var(--color-indigo-500);
  --info-section-icon-closed-color: var(--color-white);
  --info-section-icon-wrapper-closed-background-color: var(--color-indigo-500);
  --info-section-icon-wrapper-open-background-color: var(--color-gray-100);
  --info-section-rating-star-backdrop-color: var(--color-indigo-300);
  --info-section-rating-star-color: var(--color-white);
  --info-section-rating-text-color: var(--color-white);
  --info-section-rating-source-divider-color: var(--color-indigo-400);
  --info-section-rating-source-color: var(--color-white);
  --info-section-button-display: inline-block;
  --info-section-header-padding: var(--space-5x);
  --info-section-button-position: absolute;
  --info-section-button-height: 100%;
  --info-section-button-width: 100%;
  --info-section-header-align-items: initial;
  --info-section-header-flex-direction: column;
}

:root {
  --input-padding: var(--space-2-5x) var(--space-3-5x);
  --input-color: var(--color-gray-500);
  --input-placeholder-color: var(--color-gray-500);
  --input-textarea-height: var(--space-24x);
  --input-label-font-weight: var(--font-weight-normal);
}

:root {
  --key-info-background-color: var(--color-black);
  --key-info-border-bottom: none;
  --key-info-main-color: var(--color-white);
  --key-info-secondary-color: var(--color-white);
  --key-info-anchor-color-active: var(--color-indigo-500);
  --key-info-anchor-color-hover: var(--color-indigo-300);
  --key-info-anchor-color-default: var(--color-indigo-400);
  --key-info-reaction-active-color: var(--color-indigo-500);
  --key-info-reaction-hover-color: var(--color-indigo-300);
  --key-info-reaction-base-color: var(--color-indigo-400);
  --key-info-reaction-background-color: var(--color-gray-700);
  --key-info-reaction-filled-background-color: var(--color-indigo-400);
  --key-info-reaction-box-shadow: none;
  --key-info-rating-text-display: var(--color-gray-300);
  --key-info-rating-text-display-lg: var(--color-gray-200);
  --key-info-bookmark-filled-background-color: var(--color-indigo-400);
  --key-info-padding-bottom: var(--space-1x);
  --key-info-padding-bottom-inactive: var(--space-7x);
  --key-info-verified-container-display: block;
  --key-info-price-rating-margin-bottom: var(--space-2x);
  --key-info-price-font: var(--font-size-lg);
  --key-info-heading-font: var(--font-size-2xl);
  --off-market-badge-background-color: var(--color-gray-500);
  --off-market-badge-font-color: var(--color-white);
}@media (min-width: 600px) {:root {
    --key-info-heading-font: var(--font-size-4xl)
}
}@media (min-width: 1024px) {:root {
    --key-info-price-font: var(--font-size-xl)
}
}

:root {
  --last-updated-table-refresh-icon-color: var(--color-gray-500);
}

:root {
  --lead-form-footer-box-shadow: 0 -4px 4px -2px rgb(0 0 0 / 8%);
  --lead-form-form-display: initial;
  --lead-form-form-flex: initial;
  --lead-form-flex-direction: initial;
}

:root {
  --links-table-alternate-background-color: var(--color-indigo-100);
}

:root {
  --deals-border-color: var(--color-indigo-400);
}

:root {
  --location-divider-padding-bottom: var(--space-8x);
  --location-divider-top-of-page-padding-top: var(--space-3x);
}
@media (min-width: 768px) {
  :root {
    --location-divider-padding-bottom: var(--space-4x);
    --location-divider-top-of-page-padding-top: var(--space-8x);
  }
}

:root {
  --listing-card-preview-card-background-color-dark: var(--color-gray-700);
  --listing-card-preview-card-background-color-hover-dark: var(
    --color-gray-600
  );
  --listing-card-preview-card-background-color-light: var(--color-white);
  --listing-card-preview-card-background-color-hover-light: var(
    --color-gray-100
  );
  --listing-card-preview-info-card-color-dark: var(--color-white);
  --listing-card-preview-info-card-color-hover-dark: var(--color-white);
  --listing-card-preview-info-card-color-light: var(--color-black);
  --listing-card-preview-info-card-details-text-color-light: var(
    --color-gray-500
  );
  --listing-card-preview-info-card-details-text-color-dark: var(--color-white);
  --listing-card-preview-border-radius: var(--border-radius-2x);
  --listing-card-preview-border: 1px solid var(--color-gray-200);
  --listing-card-preview-primary-color: var(--color-indigo-500);
  --listing-card-preview-info-card-shadow: none;
  --listing-card-info-section-height: unset;
  --listing-card-preview-image-aspect-ratio: 5/3;
  --listing-card-preview-cta-border-radius: var(--border-radius-full);
}

:root {
  --listing-card-preview-group-li-width: 240px;
  --listing-card-preview-group-li-height: 100%;
}

:root {
  --listing-card-skeleton-height: 441px;
  --listing-card-skeleton-carousel-height: 240px;
  --listing-card-skeleton-content-gap: var(--space-4x);
  --listing-card-skeleton-content-padding: var(--space-4x);
}

:root {
  --listing-card-min-height: unset;
  --listing-card-padding: 0 0 var(--space-4x) 0;
  --listing-card-badge-star-color: var(--color-indigo-500);
  --listing-card-group-item-icon-color: var(--color-indigo-500);
  --listing-card-3d-label-color: var(--color-indigo-500);
  --listing-card-category-badge-background-color: var(--color-gray-100);
  --listing-card-border-radius: var(--border-radius-5x);
  --listing-card-overflow: hidden;
  --listing-card-carousel-min-width: 343px;
  --listing-card-bookmark-filled-background-color: var(--color-indigo-500);
  --listing-card-border-color: var(--color-gray-300);
  --listing-card-badge-background-color: var(--color-lime-300);
  --listing-card-badge-color: var(--color-black);
}

:root {
  --listing-card-scroll-snap-carousel-image-height: 255px;
}

:root {
  --logo-color: var(--color-white);
}

:root {
  --map-toggle-position: absolute;
  --map-toggle-width: var(--space-10x);
  --map-toggle-height: var(--space-10x);
  --map-toggle-padding: 0;
  --map-toggle-top: unset;
  --map-toggle-right: var(--space-5x);
  --map-toggle-left: unset;
  --map-toggle-bottom: unset;
  --map-toggle-font-size: var(--font-size-xl);
}

:root {
  --marker-element-background: var(--color-violet-600);
  --marker-element-background-hover: var(--color-violet-600);
  --marker-element-background-visited: var(--color-black);
  --marker-element-active-transform: translateY(-14px) scale(1.2);
  --marker-element-position: initial;
  --marker-element-cursor: pointer;
  --marker-element-font-family: initial;
  --marker-element-line-height: initial;
}

:root {
  --marker-favorite-icon-color: var(--color-red-400);
  --marker-favorite-icon-stroke-color: var(--color-white);
  --marker-favorite-icon-stroke-width: 0;
}

:root {
  --mobile-apps-page-background: var(--color-black);
  --mobile-apps-page-color: var(--color-white);
  --product-detail-section-order: 2;
  --mobile-apps-border-color: var(--color-gray-400);
  --mobile-apps-description-text-color: var(--color-white);
}

:root {
  --modal-panel-wrap-padding: var(--space-4x);
  --modal-panel-wrap-align-items: center;
  --modal-panel-background: var(--color-white);
  --modal-panel-border-radius: 0;
  --modal-panel-shadow: 0 0 0 0 rgb(0 0 0 / 0%), 0 0 0 0 rgb(0 0 0 / 0%),
    0 4px 8px -2px rgb(0 0 0 / 25%);
  --modal-padding: var(--space-6x) var(--space-4x) var(--space-4x);
  --modal-width: 100%;
  --modal-max-width: 100%;
  --modal-height: 100%;
  --modal-max-height: 100%;
  --modal-panel-align-items: normal;
  --modal-panel-gap: 0;
  --modal-panel-overflow: auto;
  --modal-panel-position: fixed;
  --modal-close-button-font-size: var(--font-size-2xl);
  --modal-close-button-padding: var(--space-5x) var(--space-6x);
  --modal-close-button-color: var(--color-indigo-500);
  --modal-close-button-color-hover: var(--color-indigo-600);
  --modal-close-button-position: absolute;
  --modal-close-button-top: 0;
  --modal-close-button-right: 0;
  --modal-panel-wrap-min-height: 100%;
  --modal-panel-wrap-height: auto;
  --modal-container-overflow: auto;
}@media (min-width: 768px) {:root {
    --modal-panel-border-radius: var(--border-radius-2x);
    --modal-padding: var(--space-10x) var(--space-8x) var(--space-8x);
    --modal-panel-position: relative
}
}

:root {
  --multi-select-popover-top: 50px;
  --multi-select-popover-border-radius: 0 var(--border-radius-2x)
    var(--border-radius-2x);
}

:root {
  --icons-bars-cafe-restaurant-color: var(--color-indigo-500);
  --icons-community-government-color: var(--color-teal-600);
  --icons-grocery-shopping-color: var(--color-gold-600);
  --icons-emergency-color: var(--color-red-500);
  --icons-entertainment-services-color: var(--color-violet-500);
  --icons-park-sports-color: var(--color-green-600);
  --icons-unknown-category-color: var(--color-gray-400);
}

:root {
  --nearby-properties-background-color: var(--color-black);
  --nearby-properties-heading-color: var(--color-white);
  --nearby-properties-copy-color: var(--color-white);
  --nearby-properties-grid-padding: var(--space-7x) var(--space-7x)
    var(--space-3x);
  --nearby-properties-header-margin-top: 53px;
  --nearby-properties-grid-display: grid;
  --nearby-properties-grid-row-gap: var(--space-4x);
  --nearby-properties-copy-display: block;
  --nearby-properties-grid-margin-top: var(--space-7x);
  --nearby-properties-heading-font-size: var(--font-size-5xl);
  --nearby-properties-heading-margin: 0 0 var(--space-3x);
  --nearby-properties-header-margin: 53px var(--space-6x) 0 var(--space-6x);
  --nearby-properties-line-height: 1.1;
  --nearby-properties-letter-spacing: -0.02em;
}@media (min-width: 1024px) {:root {
    --nearby-properties-heading-font-size: var(--font-size-6xl);
    --nearby-properties-header-margin: var(--space-16x) var(--space-6x) 0
      var(--space-6x)
}:root {
    --nearby-properties-grid-template-columns: repeat(3, minmax(0, 1fr))
}
}

:root {
  --no-results-image-display: block;
}

:root {
  --office-hours-container-grid-template-columns: inherit;
  --office-hours-container-grid-template-desktop: inherit;
  --office-hours-list-flex-direction: row;
  --office-hours-list-max-width: 500px;
  --office-hours-day-width: 50%;
}

:root {
  --page-header-background: var(--color-black);
  --page-header-text: var(--color-white);
  --page-header-height: var(--space-20x);
  --page-header-back-width: 52px;
  --page-header-with-back-button-height: var(--space-11x);
  --page-header-logo-color: var(--color-white);
  --page-header-border-bottom: inherit;
  --page-header-position: relative;
  --page-header-back-color: var(--color-white);
  --page-header-back-background-color: var(--color-indigo-500);
  --page-header-back-background-color-active: var(--color-indigo-400);
  --page-header-links-background-color: inherit;
  --page-header-links-color: var(--color-white);
  --page-header-links-color-hover: var(--color-indigo-300);
  --page-header-links-color-active: var(--color-indigo-500);
  --page-header-links-font-weight: bold;
  --page-header-links-text-decoration: none;
  --page-header-links-text-decoration-hover: underline;
  --page-header-favorite-color: var(--color-white);
  --page-header-favorite-color-hover: var(--color-indigo-300);
  --page-header-favorite-text-decoration: underline;
  --page-header-links-padding-left: var(--space-1x);

  --page-header-favorite-color-filled: var(--color-indigo-400);
}@media (min-width: 768px) {:root {
    --page-header-with-back-button-height: 76px;
    --page-header-back-width: 76px
}
}

:root {
  --pagination-active-color: var(--color-indigo-700);
  --pagination-button-base-color: var(--color-indigo-500);
  --pagination-button-border-color: var(--color-indigo-500);
  --pagination-button-base-color-hover: var(--color-indigo-600);
}

:root {
  --poi-pin-background: var(--color-indigo-500);
}

:root {
  --progress-ring-stroke-foreground: var(--color-indigo-300);
  --progress-ring-background-stroke-foreground: var(--color-indigo-200);
  --progress-ring-stroke-width: 7;
}

:root {
  --progress-bar-color: var(--color-indigo-500);
  --embedded-label-bar-color: var(--color-indigo-300);
}

:root {
  --pdp-flame-left-icon-color: var(--color-indigo-500);
  --pdp-flame-heading-background-color: var(--color-indigo-500);
  --pdp-tag-left-icon-color: var(--color-indigo-400);
}

:root,
::before,
::after {
  --rating-size: var(--font-size-sm);
  --rating-star-size: 13px;
  --rating-star-letter-spacing: 0;
  --rating-star-backdrop-content: '\2605\2605\2605\2605\2605';
  --rating-star-backdrop-color: var(--color-gold-200);
  --rating-star-color: var(--color-gold-500);
  --rating-star-content: '\2605\2605\2605\2605\2605';
  --rating-text-color: var(--color-gray-500);
  --rating-text-display: inline;
  --rating-star-key-info-backdrop-color: var(--color-gray-500);
}

:root {
  --report-fraud-section-child-wrapper-padding: var(--space-5x) var(--space-6x)
    0 var(--space-6x);
  --report-fraud-margin-bottom-large: var(--space-4x);
  --report-fraud-margin-bottom-medium: 0;
}

:root {
  --schedule-tour-container-background: var(--color-indigo-100);
  --schedule-tour-container-button-outlined-color: var(--color-white);
  --schedule-tour-container-button-outlined-color-hover: var(--color-white);
}

:root {
  --school-card-score-none-text: var(--color-white);
  --school-card-score-none-background-color: var(--color-gray-500);
  --school-card-score-low-text: var(--color-indigo-600);
  --school-card-score-low-background-color: var(--color-indigo-200);
  --school-card-score-medium-text: var(--color-indigo-600);
  --school-card-score-medium-background-color: var(--color-indigo-300);
  --school-card-score-high-text: var(--color-white);
  --school-card-score-high-background-color: var(--color-indigo-500);
  --school-card-background-color: unset;
  --school-card-border-width: var(--space-px);
  --school-card-border-radius: var(--border-radius-2x);
  --school-card-width: unset;
  --school-card-border: 1px solid var(--color-gray-200);
}

:root {
  --scrollable-tabs-section-arrow-button-color: var(--color-indigo-500);
  --scrollable-tabs-section-arrow-button-hover-color: var(--color-indigo-600);
}

:root {
  --search-combobox-results-list-font-size: var(--font-size-lg);
  --search-combobox-icon-font-size: var(--font-size-2xl);
  --search-combobox-icon-min-width: var(--space-6x);
  --search-combobox-results-list-vertical-border: var(--space-5x) solid
    var(--color-white);
  --search-combobox-input-padding: var(--space-4x);
  --search-combobox-list-wrap-padding: 0 var(--space-1x) var(--space-1x)
    var(--space-1x);
  --search-combobox-list-wrap-horizontal-border: var(--space-2x) solid
    var(--color-white);
  --search-combobox-list-wrap-box-shadow: -1px 0 0 0 var(--color-gray-200),
    1px 0 0 0 var(--color-gray-200), 0 1px 0 0 var(--color-gray-200),
    0 -1px 0 0 var(--color-gray-200);
  --search-combobox-list-wrap-outline: 0;
  --search-combobox-list-wrap-left: auto;
  --search-combobox-list-item-padding: var(--space-4x);
  --search-combobox-list-item-wrap-padding: 0;
  --search-combobox-list-wrap-divider-display: none;
  --search-combobox-list-wrap-visible-border-radius: 0.25rem;
  --search-combobox-results-header-border-top: none;
  --search-combobox-results-header-padding: var(--space-5x) var(--space-4x)
    var(--space-2x);
  --search-combobox-results-header-padding-first: 0 var(--space-4x);
  --search-combobox-search-button-font-size: var(--font-size-lg);
  --search-combobox-close-button-z-index: var(--z-index-2x);
  --search-combobox-close-button-color: var(--color-indigo-500);
  --search-combobox-close-button-color-hover: var(--color-indigo-600);
  --search-combobox-close-button-color-active: var(--color-indigo-600);
  --search-combobox-button-background-color: var(--color-indigo-500);
  --search-combobox-button-background-color-active: var(--color-indigo-400);
  --search-combobox-button-border-radius: var(--border-radius-2x);
  --search-combobox-list-item-hover-color: var(--color-indigo-100);
  --search-combobox-list-item-highlighted-color: var(--color-indigo-100);
  --search-combobox-list-item-icon-color: var(--color-indigo-500);
  --search-combobox-input-wrap-background: var(--color-black);
  --search-combobox-back-button-color: var(--color-indigo-400);
  --search-combobox-header-search-input-background: var(--color-white);
  --search-combobox-header-border-radius: var(--border-radius-2x);
  --search-combobox-header-visible-border-radius: var(--border-radius-2x)
    var(--border-radius-2x) 0 0;
  --search-combobox-root-border-radius: var(--border-radius-2x);
  --search-combobox-root-padding: 0;
  --search-combobox-root-border-color: transparent;
  --search-combobox-root-border-style: none;
}

:root {
  --search-results-header-anchor-text-color: var(--color-indigo-500);
  --search-results-header-filter-button-color-hover: var(--color-indigo-500);
  --search-results-header-filter-button-background-color-hover: var(
    --color-indigo-100
  );
  --search-results-header-filter-button-icon-color: var(--color-indigo-500);
  --search-results-header-outline-button-base-color: var(--color-indigo-500);
  --search-results-header-outline-button-border-color: var(--color-indigo-500);
  --search-results-header-top: 0;
  --search-results-header-text-decoration: none;
  --search-results-header-padding: var(--space-2-5x) var(--space-3x);
  --search-results-header-gap: var(--space-2x);
  --search-results-header-background-color: var(--color-white);
  --search-results-header-border: 1px solid var(--color-gray-200);
  --search-results-header-button-font-size: var(--font-size-body);
  --search-results-header-button-height: var(--space-10x);
  --search-results-header-input-font-size: var(--font-size-body);
  --search-results-header-input-container-border: 1px solid
    var(--color-gray-200);
}

:root {
  --search-view-bar-background: var(--color-indigo-500);
}

:root {
  --security-page-background: var(--color-black);
  --security-page-color: var(--color-white);
  --security-page-accent: var(--color-indigo-400);
}

:root {
  --select-width: 100%;
  --select-height: 40px;
  --select-height-focused: 40px;
  --select-padding: 0 var(--space-4x);
  --select-display: inline-flex;
  --select-align-items: center;
  --select-position: relative;
  --select-justify-content: space-between;
  --select-item-height: 40px;
  --select-border-radius: var(--border-radius-2x);
  --select-border-width: 1px;
  --select-border-color: var(--color-gray-200);
  --select-border-color-hover: var(--color-gray-300);
  --select-background-color: var(--color-white);
  --select-background-color-hover: var(--color-indigo-100);
  --select-icon-color: var(--color-indigo-500);
  --select-trigger-gap: 0;
  --select-trigger-color: var(--color-gray-500);
  --select-item-background-color: var(--color-white);
  --select-item-background-color-hover: var(--color-indigo-100);
  --select-item-background-color-active: var(--color-indigo-100);
  --select-item-background-color-active-hover: var(--color-indigo-100);
  --select-item-background-color-selected: var(--color-indigo-500);
  --select-item-background-color-selected-hover: var(--color-indigo-500);
  --select-item-color: var(--color-indigo-500);
  --select-item-color-selected: var(--color-white);
  --select-item-padding: 0;
  --select-results-padding: var(--space-1x);
  --select-font-color: var(--color-gray-500);
  --select-results-width: 100%;
  --select-min-width: initial;
  --select-z-index: var(--z-index-5x);
}

:root {
  --soft-filters-home-icon-color: var(--color-indigo-500);
}

:root {
  --seo-free-form-width: 100%;
  --seo-free-form-height: 100%;
  --seo-free-form-overflow: hidden;
  --seo-free-form-font-size: var(--font-size-body);
  --seo-free-form-mobile-padding: var(--space-6x);
  --seo-free-form-padding: var(--space-6x) 0;
  --seo-free-form-text-margin: var(--space-2-5x) 0;
  --seo-free-form-text-color: var(--color-gray-500);
  --seo-free-form-h2-mobile-font-size: var(--font-size-2xl);
  --seo-free-form-h2-font-size: var(--font-size-4xl);
  --seo-free-form-h3-font-size: var(--font-size-lg);
  --seo-free-form-lists-padding: 0;
  --seo-free-form-lists-list-style: none;
}

:root {
  --seo-links-section-padding: var(--space-10x) 0 var(--space-16x) 0;
  --seo-links-section-padding-md: var(--space-10x) 0 var(--space-16x) 0;
  --seo-links-section-padding-lg: var(--space-10x) 0 var(--space-16x) 0;
  --seo-links-section-last-child-padding-bottom: var(--space-6x);
  --seo-links-section-before-margin: var(--space-6x) 0;
  --seo-links-section-before-margin-lg: var(--space-11x) 0;
  --seo-links-section-before-content: '';
  --seo-links-section-before-display: block;
  --seo-links-section-before-border-top: 1px solid var(--color-gray-200);
  --seo-links-section-border-bottom: 1px solid var(--color-gray-200);
  --seo-links-section-margin: 0 var(--space-3x);
  --seo-links-section-margin-md: 0 var(--space-6x);
  --seo-links-section-margin-lg: 0 var(--space-6x);
}

:root {
  --listing-card-preview-group-columns: auto;
  --listing-card-preview-group-gap: var(--space-5x);
}

:root {
  --sitemap-page-background: var(--color-black);
  --sitemap-page-heading-color: var(--color-white);
  --sitemap-page-column-heading-color: var(--color-white);
  --sitemap-page-heading-divider-background-color: var(--color-indigo-400);
  --sitemap-anchor-text-color-default: var(--color-indigo-400);
  --sitemap-anchor-text-color-hover: var(--color-indigo-300);
  --sitemap-anchor-text-color-active: var(--color-indigo-500);
}

:root {
  --spinner-accent-color: var(--color-indigo-500);
  --spinner-size: var(--space-4x);
  --spinner-speed: 1s;
  --spinner-width: 2px;
}

:root {
  --state-lander-page-background: var(--color-black);
  --state-lander-page-breadcrumbs-anchor-active-color: var(--color-indigo-500);
  --state-lander-page-breadcrumbs-anchor-color: var(--color-indigo-400);
  --state-lander-page-breadcrumbs-anchor-hover-color: var(--color-indigo-300);
  --state-lander-page-breadcrumbs-headline-color: var(--color-white);
  --state-lander-page-city-links-text-color: var(--color-indigo-400);
  --state-lander-page-color: var(--color-white);
  --state-lander-page-divider-color: var(--color-gray-400);
  --state-lander-page-hero-list-color: var(--color-white);
  --state-lander-page-overview-text-color: var(--color-white);
  --state-lander-page-search-combo-box-border-color: transparent;
}

:root {
  --sticky-footer-icon-cta-active-color: var(--color-indigo-600);
  --sticky-footer-icon-cta-hover-color: var(--color-indigo-400);
  --sticky-footer-icon-cta-base-color: var(--color-indigo-500);
  --sticky-footer-icon-cta-background-color: var(--color-white);
  --sticky-footer-icon-cta-hover-background-color: var(--color-white);
  --sticky-footer-icon-cta-filled-color: var(--color-indigo-500);
  --sticky-footer-icon-cta-margin: unset;
  --sticky-footer-icon-cta-box-shadow: unset;
}

:root {
  --sticky-header-mobile: var(--color-indigo-500);
  --sticky-header-desktop: var(--color-indigo-400);
  --sticky-header-background-color: var(--color-black);
  --sticky-header-hover-color: var(--color-indigo-300);
  --sticky-header-hover-border-color: var(--color-indigo-300);
  --sticky-header-button-background-color: var(--color-black);
}

:root {
  --tab-button-active-border-color: var(--color-indigo-500);
  --tab-button-padding: 0 0 var(--space-1x) 0;
  --tab-button-font-weight: var(--font-weight-semibold);
}

:root {
  --table-font-weight: var(--font-weight-semibold);
  --table-background-color-odd: var(--color-indigo-100);
  --table-border: none;
  --table-text-align: right;
  --table-tbody-td-padding: var(--space-5x) 0;
  --table-tbody-td-color: var(--color-black);
  --table-tbody-td-first-child-padding: var(--space-3x);
  --table-tbody-td-first-child-color: var(--color-indigo-500);
}

:root {
  --thank-you-check-mark-background-color: var(--color-indigo-500);
  --thank-you-close-button-color: var(--color-indigo-400);
  --thank-you-header-background-color: var(--color-black);
  --thank-you-header-text-color: var(--color-white);
  --thank-you-similar-properties-button-border-radius: var(--space-1x);
}

:root {
  --thank-you-redesign-header-background-color: var(--color-indigo-300);
  --header-modal-close-color: var(--color-blue-400);
  --header-modal-background-color: var(--color-indigo-100);
  --property-image-border-color: var(--color-indigo-500);
  --loading-bar-wrapper-background-color: var(--color-indigo-200);
  --loading-bar-background-color: var(--color-indigo-500);
  --loading-bar-height: var(--space-1x);
  --loading-bar-max-width: 100%;
}

:root {
  --toggle-button-color: var(--color-indigo-500);
  --toggle-button-hover-color: var(--color-indigo-500);
  --toggle-button-color-selected: var(--color-white);
  --toggle-button-color-selected-hover: var(--color-white);
  --toggle-button-border-color: var(--color-gray-200);
  --toggle-button-hover-border-color: var(--color-gray-200);
  --toggle-button-selected-hover-border-color: var(--color-gray-200);
  --toggle-button-border-color-selected: var(--color-white);
  --toggle-button-hover-background-color: var(--color-indigo-100);
  --toggle-button-background-color-selected-hover: var(--color-indigo-500);
  --toggle-button-background-color-selected: var(--color-indigo-500);
  --toggle-button-font-weight-selected: var(--font-weight-normal);
  --toggle-button-border-width: 1px;
  --toggle-button-modal-color: var(--color-gray-500);
  --toggle-button-modal-hover-color: var(--color-gray-500);
  --toggle-button-modal-border-color: var(--color-gray-200);
  --toggle-button-modal-hover-border-color: var(--color-gray-200);
  --toggle-button-font-weight: var(--font-weight-semibold);
  --toggle-button-min-height: var(--space-10x);
  --toggle-button-group-gap: var(--space-3x);
}

:root {
  --tour-wizard-heading-color: var(--color-black);
  --tour-wizard-text-color: var(--color-indigo-500);
  --tour-wizard-accent-color: var(--color-indigo-500);
  --tour-wizard-accent-color-light: var(--color-indigo-100);
  --tour-wizard-accent-color-hover: var(--color-indigo-600);
}

:root {
  --user-menu-item-color: var(--color-white);
  --user-menu-items-background-color: var(--color-gray-700);
  --user-menu-items-border: 1px solid var(--color-gray-600);
  --user-menu-item-hover-color: var(--color-white);
  --user-menu-item-hover-background-color: var(--color-gray-600);
}

:root {
  --marker-popup-mobile-units-available-text: var(--color-indigo-500);
  --icon-cta-filled-color: var(--bookmark-filled-background-color);
  --bookmark-filled-background-color: var(--color-indigo-500);
}

:root {
  --tab-icon-share-color: var(--color-indigo-500);
}

:root {
  --score-card-icon-background: transparent;
  --score-card-icon-border-radius: none;
  --score-card-icon-color: var(--color-black);
  --score-card-icon-height: unset;
  --score-card-icon-width: unset;
  --score-card-description-padding: 0 0 0 var(--space-6x);
}

:root {
  --discount-badge-background-color: var(--color-lime-300);
  --discount-badge-color: var(--color-black);
  --discount-badge-border-radius: var(--border-radius-full);
  --discount-badge-padding: var(--space-0-5x) var(--space-2x);
}

:root {
  --radio-button-checked-color: var(--color-indigo-500);
}

:root {
  --detail-page-container-vertical-margin: var(--space-8x);
  --detail-page-container-margin: var(--detail-page-container-vertical-margin) 0;
  --detail-page-footer-padding-bottom: var(--space-18x);
  --listing-info-media-unavail-divider-display: none;
  --listing-info-media-unavail-padding: 0;
  --sticky-box-offset-top: -280px;
  --sticky-box-offset-top-with-pmc: -316px;
  --sticky-box-top: 80px;
  --sticky-box-default-top: 15px;

  /* Rent doesn't have deals section, so reuse styles */
  --sticky-box-offset-top-with-deals: var(--sticky-box-offset-top);
  --sticky-box-offset-top-with-pmc-deals: var(--sticky-box-offset-top-with-pmc);
}@media (min-width: 768px) {:root {
    --detail-page-footer-padding-bottom: var(--space-20x);
    --listing-info-media-unavail-padding: var(--space-8x) 0
}
}@media (min-width: 1126px) {:root {
    --detail-page-footer-padding-bottom: 0
}
}

:root {
  --zoom-button-color: var(--color-indigo-500);
  --map-error-background-color: var(--color-indigo-100);
  --map-error-color: var(--color-indigo-500);
}

:root {
  --listing-card-carousel-display: block;
  --listing-card-carousel-height: 240px;
  --listing-card-carousel-width: 100%;
}

:root {
  --button-background-color: var(--color-indigo-100);
  --button-color: var(--color-indigo-600);
}

:root {
  --saved-searches-page-delete-icon-color: var(--color-indigo-500);
  --saved-searches-page-delete-icon-color-hover: var(--color-indigo-600);
  --saved-searches-page-bookmark-icon-color: var(--color-indigo-400);
}

:root {
  --switch-background-color: var(--color-indigo-500);
}

:root {
  --saved-search-edit-button-background-color: var(--color-indigo-100);
  --saved-search-edit-button-background-color-hover: var(--color-indigo-200);
  --saved-search-edit-button-border-color: var(--color-indigo-300);
  --saved-search-edit-button-border-color-hover: var(--color-indigo-400);
  --saved-search-save-button-font-size: var(--font-size-body);
  --saved-search-button-background-color: var(--color-pink-500);
  --saved-search-button-background-color-hover: var(--color-pink-600);
  --saved-search-button-background-color-active: var(--color-pink-700);
}

:root {
  --section-truncate-margin: 0;
  --section-truncate-color: initial;
  --section-truncate-padding: 0;
  --section-truncate-font-size: initial;
  --section-truncate-anchor-padding: var(--space-8x) 0 0;
  --section-truncate-white-space: initial;
}

:root {
  --search-results-li-highlight-box-shadow: 0 0 0 var(--space-1x)
    var(--color-indigo-400);
  --search-results-container-mobile-padding: 0 var(--space-2x) var(--space-4x);
  --search-results-container-desktop-padding: var(--space-4x) var(--space-6x);
  --search-results-listing-card-min-height: 443px;
  --search-results-listing-card-skeleton-height: 443px;
}

:root {
  --list-property-home-button-width: auto;
  --list-property-home-button-border-radius: var(--border-radius-2x);
  --list-property-home-container-flex-direction: column;
  --list-property-page-background: var(--color-indigo-100);
  --list-property-page-background-value-prop: var(--color-indigo-100);
  --list-property-page-theme-background: transparent;
  --list-property-page-theme-color: var(--color-black);
  --list-property-logos-margin: 0;
  --list-property-logos-gap: 61px;
  --list-property-block-flex-direction: column;
  --list-property-simplify-flex-direction: row-reverse;
  --list-property-home-text-max-width: 329px;
  --list-property-value-props-padding: var(--space-8x) 0;
}@media (min-width: 768px) {:root {
    --list-property-value-props-padding: var(--space-8x) 0 var(--space-14x);
    --list-property-home-container-flex-direction: row;
    --list-property-block-flex-direction: row
}
}

:root {
  --filter-modal-anchor-text-size: 16px;
}@media (min-width: 768px) {:root {
    --filter-modal-anchor-text-size: 14px
}
}

:root {
  --tooltip-link-color: var(--color-indigo-400);
}

:root {
  --about-property-details-display: block;
  --about-property-active-block-padding-bottom: var(--space-8x);
}

:root {
  --pet-policies-description-section-padding: 0 0 var(--space-5x);
  --pet-policies-description-section-width: auto;
  --pet-policies-description-section-border-bottom: 1px solid
    var(--color-gray-200);
  --pet-policies-description-section-margin: 0 0 var(--space-5x);
  --pet-policies-description-list-item-margin-top: var(--space-4x);
  --pet-policies-label-font-weight: var(--font-weight-semibold);
  --pet-policies-label-margin: 0 0 var(--space-2x);
  --pet-policies-label-display: block;
  --pet-policies-label-color: var(--color-black);
  --pet-policies-comment-display: block;
  --pet-policies-pet-header-margin-bottom: var(--space-5x);
  --pet-policies-formatted-margin: 0;
}@media (min-width: 768px) {:root {
    --pet-policies-description-section-padding: 0 var(--space-4x) 0 0;
    --pet-policies-description-section-width: 33%
}
}

:root {
  --content-grid-section-container-display: flex;
  --content-grid-last-item-list-display: flex;
  --content-grid-last-item-list-width: 50%;
  --content-grid-last-item-list-column-count: 3;
  --content-grid-list-item-margin: var(--space-3x) 0 0;
  --content-grid-list-display: block;
  --content-grid-list-style-type: none;
  --content-grid-list-width: 50%;
  --content-grid-list-padding: 0;
  --content-grid-list-margin: 0;
}@media (min-width: 768px) {:root {
    --content-grid-last-item-list-display: block;
    --content-grid-last-item-list-width: 100%;
    --content-grid-list-item-margin: var(--space-1x) 0 0 var(--space-5x);
    --content-grid-list-style-type: disc;
    --content-grid-list-width: 100%
}
}



@layer tokens, reset, utilities, components, molecules;

